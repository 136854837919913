<template>
    <div class="container">
        <el-select v-model="value" placeholder="请选择主题" @change="ChangeTheme(value)" size="mini" class="SelectTheme">
            <el-option
            v-for="item in themeArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
      </el-select>
      <el-select v-model="langValue" placeholder="请选择语言" @change="ChangeLange(langValue)" size="mini" class="SelectLang">
            <el-option
            v-for="item in langArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
      </el-select>
      <editor
        ref="aceEditor"
        v-model="content"
        @init="editorInit"
        width="500"
        height="350"
        :lang="langValue"
        :theme="theme"
        :options="option"
      ></editor>
    </div>
</template>
<script>
export default {
    data() {
        return {
        content: "",
        theme:'',
        num:0,
        value:'',
        option:{
            enableBasicAutocompletion: true, // 启用基本自动完成
            enableSnippets: true, // 启用代码段
            enableLiveAutocompletion: true, // 启用实时自动完成
            printMarginColumn: 30,
            displayIndentGuides: false, // 显示参考线
            enableEmmet: true, // 启用Emmet
            tabSize: 6, // 标签大小
            fontSize: 14, // 设置字号
            useWorker: true, // 使用辅助对象
            showPrintMargin: false, //去除编辑器里的竖线
            enableMultiselect: true, //     选中多处
            readOnly: false, // 是否只读
            showFoldWidgets: true, // 显示折叠部件
            fadeFoldWidgets: true, // 淡入折叠部件
            wrap: true
        },
        themeArr:[
                    {
                        label:'ambiance',
                        value:'ambiance'
                    },
                    {
                        label:'chaos',
                        value:'chaos'
                    },
                    {
                        label:'chrome',
                        value:'chrome'
                    },
                    {
                        label:'clouds',
                        value:'clouds'
                    },
                    
                    {
                        label:'cobalt',
                        value:'cobalt'
                    },
                    
                    {
                        label:'dawn',
                        value:'dawn'
                    },
                    {
                        label:'dracula',
                        value:'dracula'
                    },
                    
                    {
                        label:'eclipse',
                        value:'eclipse'
                    },
                    {
                        label:'github',
                        value:'github'
                    },
                    {
                        label:'gob',
                        value:'gob'
                    },
                    {
                        label:'gruvbox',
                        value:'gruvbox'
                    },
                    
                    {
                        label:'iplastic',
                        value:'iplastic'
                    },
                    
                    {
                        label:'kr_theme',
                        value:'kr_theme'
                    },
                    {
                        label:'kuroir',
                        value:'kuroir'
                    },
                    {
                        label:'merbivore',
                        value:'merbivore'
                    },
                    
                    {
                        label:'monokai',
                        value:'monokai'
                    },
                    {
                        label:'sqlserver',
                        value:'sqlserver'
                    },
                    {
                        label:'terminal',
                        value:'terminal'
                    },
                    {
                        label:'textmate',
                        value:'textmate'
                    },
                    {
                        label:'tomorrow',
                        value:'tomorrow'
                    },
                    {
                        label:'twilight',
                        value:'twilight'
                    },
                    
                    {
                        label:'xcode',
                        value:'xcode'
                    },
        ],
        arr:[
            'ambiance',
            'chaos',
            'chrome',
            'clouds',
            'clouds_midnight',
            'cobalt',
            'crimson_editor',
            'dawn',
            'dracula',
            'dreamweaver',
            'eclipse',
            'github',
            'gob',
            'gruvbox',
            'idle_fingers',
            'iplastic',
            'katzenmilch',
            'kr_theme',
            'kuroir',
            'merbivore',
            'merbivore_soft',
            'monokai',
            'mono_industrial',
            'pastel_on_dark',
            'solarized_dark',
            'solarized_light',
            'sqlserver',
            'terminal',
            'textmate',
            'tomorrow',
            'tomorrow_night',
            'tomorrow_night_blue',
            'tomorrow_night_bright',
            'tomorrow_night_eighties',
            'twilight',
            'vibrant_ink',
            'xcode',
        ],
        langArr:[
            {
                label:'Javascript',
                value:'javascript'
            },
            {
                label:'Dockerfile',
                value:'dockerfile'
            },
            {
                label:'Python',
                value:'python'
            },
            {
                label:'PHP',
                value:'php'
            },
            {
                label:'HTML',
                value:'html'
            },
            {
                label:'Ruby',
                value:'ruby'
            },
            {
                label:'CSS',
                value:'css'
            },
            {
                label:'JAVA',
                value:'java'
            },
            {
                label:'XML',
                value:'xml'
            },
            {
                label:'MySQL',
                value:'mysql'
            },
            {
                label:'Json',
                value:'json'
            },
        ],
        langValue:'javascript'
        };
    },
    components: {
        editor: require("vue2-ace-editor")
    },
    methods: {
        editorInit() {//初始化
        //语言拓展
        require("brace/ext/language_tools"); //language extension prerequsite...
        require('brace/ext/beautify')
        require("brace/ext/keybinding_menu");
        //    代码语言
        require("brace/mode/javascript"); //language
        require("brace/mode/dockerfile")
        require('brace/mode/python')
        require('brace/mode/php')
        require('brace/mode/html')
        require('brace/mode/ruby')
        require('brace/mode/css')
        require('brace/mode/java')
        require('brace/mode/mysql')
        require('brace/mode/json')
        require('brace/mode/xml')

        //   代码主题
        require('brace/theme/chrome')
        require('brace/theme/github')
        require('brace/theme/eclipse')
        require('brace/theme/dracula')

        //   代码片段
        require("brace/snippets/javascript");
        require("brace/snippets/dockerfile");
        require("brace/snippets/python");
        require("brace/snippets/php");
        require("brace/snippets/html");
        require("brace/snippets/ruby");
        require("brace/snippets/css");
        require('brace/snippets/java')
        require('brace/snippets/mysql')
        require('brace/snippets/json')
        require('brace/snippets/xml')
        
        for (let i = 0; i < this.arr.length; i++) {//方便看哪个主题好看，循坏加载了所有主题，通过点击按钮切换
            require("brace/theme/"+this.arr[i])
        }
        },
        ChangeTheme(val){
            this.theme = val
        },
        ChangeLange(val){
            this.langValue = val
        }  
    },
    mounted() {
        this.editorInit();
        this.theme = 'monokai'
        this.$refs.aceEditor.editor.setValue('{}')
    }
};
</script>
<style scoped>
.container{
    position: relative;
    height: 350px;
    width: 615px;
    background: white;
    border-radius: 0px 4px 4px 0px;
}
.SelectTheme{
    position: absolute;
    width: 110px;
    z-index: 99;
    right: 3px;
    top: 40px;
}
.SelectLang{
    position: absolute;
    width: 110px;
    z-index: 99;
    top: 3px;
    right: 3px;
}
</style>